import { Box, Divider, Text } from '@chakra-ui/react'
import React from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts'

const Top10Studios = ({ data }) => {
    if (!data || !data.studiosData) {
        return null
    }

    return (
        <Box p={4} borderWidth={2} borderRadius={5} shadow='base'>
            <Text fontSize="lg" textAlign="center" fontWeight={500}>Top 10 Studios</Text>
            <Divider mt={2} mb={2} />
            <ResponsiveContainer width="100%" height={220}>
                <BarChart width={400} height={220} data={data.studiosData.slice(0, 10)} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
                    <XAxis dataKey="studio" fontSize={14} />
                    <YAxis yAxisId={0} />
                    <Tooltip />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Bar fill="#456990" dataKey="weekPrevDau" yAxisId={0} name="Week Ago" />
                    <Bar fill="#49BEAA" dataKey="onePrevDau" yAxisId={0} name="Day Ago" />
                    <Bar fill="#EF767A" dataKey="dau" yAxisId={0} name="DAU" />
                </BarChart>
            </ResponsiveContainer>
        </Box>
    )
}

export default Top10Studios