import React, { useState, useEffect } from 'react'
import { Flex, Image, Progress, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'

import DAUBreakdown from './DAUBreakdown';
import Top10Android from './Top10Android';
import Top10IOS from './Top10IOS';
import Top10Web from './Top10Web';
import CSVDataMapper from './CSVDataMapper';
import Top10Studios from './Top10Studios';
import RawBreakdown from './RawBreakdown';

import { ByteBrew } from 'bytebrew-web-sdk';

import { useWindowResize } from './Utilities/useWindowResize'

const Home = () => {
    const [data, setData] = useState(null)
    const [statusMessage, setStatusMessage] = useState(null)
    const [progress, setProgress] = useState(-1)

    const defaultDAUMinCutoff = '100';
    const defaultDAUMaxCutoff = 'Infinity';

    const defaultDAUWeekAgoMinCutoff = '0';
    const defaultDAUWeekAgoMaxCutoff = 'Infinity';

    const defaultStudioFilter = 'all';
    const defaultPercentileCutoff = '0';

    const [sortKey, setSortKey] = useState('dau');

    const [dauMinCutoff, setDauMinCutoff] = useState(defaultDAUMinCutoff);
    const [dauMaxCutoff, setDauMaxCutoff] = useState(defaultDAUMaxCutoff);

    const [dauWeekAgoMinCutoff, setDauWeekAgoMinCutoff] = useState(defaultDAUWeekAgoMinCutoff);
    const [dauWeekAgoMaxCutoff, setDauWeekAgoMaxCutoff] = useState(defaultDAUWeekAgoMaxCutoff);

    const [studioFilter, setStudioFilter] = useState(defaultStudioFilter);
    const [percentileCutoff, setPercentileCutoff] = useState(defaultPercentileCutoff);
    const [inverted, setInverted] = useState(false);

    const [tabIndex, setTabIndex] = useState(0)

    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.8;

    useEffect(() => {
        console.log(data)
    }, [data])

    const updateProgress = async (newProgress) => {
        return new Promise(resolve => {
            setTimeout(() => {
                setProgress(newProgress);
                resolve();
            }, 50);
        });
    };

    const processData = async (promises) => {
        if (promises) {
            try {
                await updateProgress(0)
                const allData = await promises.allDataStep
                await updateProgress(10)
                const iosData = await promises.iosDataStep
                await updateProgress(20)
                const totalIOSDAUStep = await promises.totalIOSDAUStep(iosData)
                await updateProgress(35)
                const androidData = await promises.androidDataStep
                await updateProgress(45)
                const totalAndroidDAUStep = await promises.totalAndroidDAUStep(androidData)
                await updateProgress(60)
                const webData = await promises.webDataStep
                await updateProgress(75)
                const totalWebDAUStep = await promises.totalWebDAUStep(webData)
                await updateProgress(90)

                const studiosData = await promises.dauByStudio
                await updateProgress(100)

                const totalDAU = totalIOSDAUStep.totalIOSDAU + totalAndroidDAUStep.totalAndroidDAU + totalWebDAUStep.totalWebDAU
                const totalOnePrevDAU = totalIOSDAUStep.totalIOSOnePrevDAU + totalAndroidDAUStep.totalAndroidOnePrevDAU + totalWebDAUStep.totalWebOnePrevDAU
                const totalWeekPrevDAU = totalIOSDAUStep.totalIOSWeekPrevDAU + totalAndroidDAUStep.totalAndroidWeekPrevDAU + totalWebDAUStep.totalWebWeekPrevDAU

                const completeData = {
                    allData: allData,
                    androidData: androidData,
                    iosData: iosData,
                    webData: webData,
                    studiosData: studiosData.studiosData,

                    totalDAU: totalDAU,
                    totalOnePrevDAU: totalOnePrevDAU,
                    totalWeekPrevDAU: totalWeekPrevDAU,

                    totalOnePrevDAUPercentageChange: totalOnePrevDAU !== 0 ? (totalDAU - totalOnePrevDAU) / totalOnePrevDAU * 100 : 0,
                    totalWeekPrevDAUPercentageChange: totalWeekPrevDAU !== 0 ? (totalDAU - totalWeekPrevDAU) / totalWeekPrevDAU * 100 : 0,

                    androidTotalDAU: totalAndroidDAUStep.totalAndroidDAU,
                    androidTotalOnePrevDAU: totalAndroidDAUStep.totalAndroidOnePrevDAU,
                    androidTotalWeekPrevDAU: totalAndroidDAUStep.totalAndroidWeekPrevDAU,

                    totalAndroidOnePrevDAUPercentageChange: totalAndroidDAUStep.totalAndroidOnePrevDAUPercentageChange,
                    totalAndroidWeekPrevDAUPercentageChange: totalAndroidDAUStep.totalAndroidWeekPrevDAUPercentageChange,

                    iosTotalDAU: totalIOSDAUStep.totalIOSDAU,
                    iosTotalOnePrevDAU: totalIOSDAUStep.totalIOSOnePrevDAU,
                    iosTotalWeekPrevDAU: totalIOSDAUStep.totalIOSWeekPrevDAU,

                    totalIOSOnePrevDAUPercentageChange: totalIOSDAUStep.totalIOSOnePrevDAUPercentageChange,
                    totalIOSWeekPrevDAUPercentageChange: totalIOSDAUStep.totalIOSWeekPrevDAUPercentageChange,

                    webTotalDAU: totalWebDAUStep.totalWebDAU,
                    webTotalOnePrevDAU: totalWebDAUStep.totalWebOnePrevDAU,
                    webTotalWeekPrevDAU: totalWebDAUStep.totalWebWeekPrevDAU,

                    totalWebOnePrevDAUPercentageChange: totalWebDAUStep.totalWebOnePrevDAUPercentageChange,
                    totalWebWeekPrevDAUPercentageChange: totalWebDAUStep.totalWebWeekPrevDAUPercentageChange,

                    top10StudiosDAU: studiosData.top10StudiosDAU,
                    top10StudiosOnePrevDAU: studiosData.top10StudiosOnePrevDAU,
                    top10StudiosWeekPrevDAU: studiosData.top10StudiosWeekPrevDAU,

                    top10StudiosOnePrevDAUPercentageChange: studiosData.top10StudiosOnePrevDAUPercentageChange,
                    top10StudiosWeekPrevDAUPercentageChange: studiosData.top10StudiosWeekPrevDAUPercentageChange,
                }

                if (iosData.length <= 0 || androidData.length <= 0 || studiosData.studiosData.length <= 0) {
                    throw new Error('Error reading file!')
                } else {
                    setData(completeData)
                    setProgress(-1)
                    setStatusMessage(null)

                    ByteBrew.newCustomEvent("DataUploaded", { iosDataLength: iosData.length, androidDataLength: androidData.length })
                }
            } catch (error) {
                console.log(error)
                setData(null)
                setProgress(-1)
                setStatusMessage(error)
            }
        } else {
            setData(null)
            setProgress(-1)
            setStatusMessage(null)
        }
    };

    const getRawBreakdown = (breakdownData) => {
        return (
            <RawBreakdown data={breakdownData} isNarrow={isNarrow}
                sortKey={sortKey} setSortKey={setSortKey}
                dauMinCutoff={dauMinCutoff} setDauMinCutoff={setDauMinCutoff}
                dauMaxCutoff={dauMaxCutoff} setDauMaxCutoff={setDauMaxCutoff}
                dauWeekAgoMinCutoff={dauWeekAgoMinCutoff} setDauWeekAgoMinCutoff={setDauWeekAgoMinCutoff}
                dauWeekAgoMaxCutoff={dauWeekAgoMaxCutoff} setDauWeekAgoMaxCutoff={setDauWeekAgoMaxCutoff}
                studioFilter={studioFilter} setStudioFilter={setStudioFilter}
                percentileCutoff={percentileCutoff} setPercentileCutoff={setPercentileCutoff}
                inverted={inverted} setInverted={setInverted}
                defaultDAUCutoff={defaultDAUMinCutoff} defaultStudioFilter={defaultStudioFilter} defaultPercentileCutoff={defaultPercentileCutoff} />
        )
    }

    return (
        <Stack direction="column" spacing={4} mb={10} >
            {
                !isNarrow ?
                    <Flex direction="row" justifyContent="space-between">
                        <Image mt={6} mr={10} ml={10} src={'/bytebrewfulllogo.png'} alt="Bytebrew Logo" width={200} height={50} objectFit='scale-down' />
                        <CSVDataMapper setData={setData} onDataMapped={(promises) => {
                            processData(promises);
                        }} />
                    </Flex>
                    :
                    <Flex direction="column" justifyContent="space-between">
                        <Image mt={6} mr={5} ml={5} src={'/bytebrewfulllogo.png'} alt="Bytebrew Logo" width={200} height={50} objectFit='scale-down' />
                        <CSVDataMapper setData={setData} isNarrow={isNarrow} onDataMapped={(promises) => {
                            processData(promises);
                        }} />
                    </Flex>
            }
            {
                progress >= 0 &&
                <Progress mt={6} mr={isNarrow ? 5 : 10} ml={isNarrow ? 5 : 10} value={progress} borderRadius={10} />
            }
            {
                !data ? (
                    <Text fontSize="lg" textAlign="center" fontWeight={500}>
                        {statusMessage ? `${statusMessage}` : `Upload a CSV file to get started!`}
                    </Text>
                ) : (
                    <>
                        <DAUBreakdown data={data} isNarrow={isNarrow} />
                        <Tabs mr={isNarrow ? 5 : 10} ml={isNarrow ? 5 : 10} onChange={(index) => setTabIndex(index)}>
                            <TabList>
                                <Tab onClick={() => { ByteBrew.newCustomEvent("iOSTab") }}>
                                    iOS
                                </Tab>
                                <Tab onClick={() => { ByteBrew.newCustomEvent("AndroidTab") }}>
                                    Android
                                </Tab>
                                <Tab onClick={() => { ByteBrew.newCustomEvent("WebTab") }}>
                                    Web
                                </Tab>
                                <Tab onClick={() => { ByteBrew.newCustomEvent("StudiosTab") }}>
                                    Studios
                                </Tab>
                                <Tab onClick={() => { ByteBrew.newCustomEvent("AllTab") }}>
                                    All
                                </Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    {
                                        tabIndex === 0 &&
                                        <>
                                            <Top10IOS data={data} />
                                            {
                                                getRawBreakdown(data.iosData)
                                            }
                                        </>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {
                                        tabIndex === 1 &&
                                        <>
                                            <Top10Android data={data} />
                                            {
                                                getRawBreakdown(data.androidData)
                                            }
                                        </>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {
                                        tabIndex === 2 && (
                                            data && data.webData && data.webData.length > 0 ? (
                                                <>
                                                    <Top10Web data={data} />
                                                    {
                                                        getRawBreakdown(data.webData)
                                                    }
                                                </>
                                            ) : (
                                                <Text fontSize="lg" textAlign="center" fontWeight={500}>
                                                    No Web data available!
                                                </Text>
                                            )
                                        )
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {
                                        tabIndex === 3 &&
                                        <>
                                            <Top10Studios data={data} />
                                            {
                                                getRawBreakdown(data.studiosData)
                                            }
                                        </>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {
                                        tabIndex === 4 &&
                                        getRawBreakdown(data.allData)
                                    }
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </>
                )
            }
        </Stack>
    )
}

export default Home