import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import { useCSVReader } from 'react-papaparse';

export default function CSVReader({ onDataReceived, isNarrow }) {
    const { CSVReader } = useCSVReader();

    return (
        <CSVReader
            onUploadAccepted={(results) => {
                onDataReceived(results);
            }}
            config={{
                quoteChar: '"', // Specifies the character to use for quoting fields
                escapeChar: '"', // Specifies the character to use for escaping quotes
            }}
        >
            {({
                getRootProps,
                acceptedFile,
                getRemoveFileProps,
            }) => (
                <>
                    <Flex justifyContent="center" alignItems="center" flexDirection="row" gap={4}>
                        <Button p={6} {...getRootProps()}>
                            Browse
                        </Button>
                        <Box borderWidth={2} borderRadius={5} minWidth={isNarrow ? 0 : 150} minHeight={10}>
                            <Text m={2} fontSize="sm" fontWeight="bold" align='center' color="gray.500">
                                {(acceptedFile ? acceptedFile.name : 'No file selected')}
                            </Text>
                        </Box>
                        <Button p={6} color='red' {...getRemoveFileProps()} onClick={
                            () => {
                                onDataReceived(null);
                            }
                        }>
                            Remove
                        </Button>
                    </Flex>
                </>
            )}
        </CSVReader>
    );
}