import { Box, Divider, Flex, Text, SimpleGrid } from '@chakra-ui/react'
import React from 'react'

import NumberArrowPercentDisplay from './NumberArrowPercentDisplay'

const DAUBreakdown = ({ data, isNarrow }) => {
    const makeBox = (label, totalNumber, dayBeforeNumber, weekBeforeNumber, dayPercentChange, weekPercentChange, topMargin) => {
        return (
            <Box p={4} mt={topMargin} mr={isNarrow ? 0 : 10} ml={isNarrow ? 0 : 10} borderWidth={2} borderRadius={5}>
                <Text fontSize="lg" textAlign="center" fontWeight={500}>{label}</Text>
                <Divider mt={2} mb={2} />
                <Text fontSize="lg" textAlign="center">
                    {totalNumber.toLocaleString()}
                </Text>
                <Divider mt={2} mb={2} />
                <Text fontSize="lg" textAlign="center" fontWeight={500}>Previous</Text>
                <Divider mt={2} mb={2} />
                <NumberArrowPercentDisplay label={"Day"} totalNumber={dayBeforeNumber} percentChange={dayPercentChange} fontSizeOverride='lg' />
                <NumberArrowPercentDisplay label={"Week"} totalNumber={weekBeforeNumber} percentChange={weekPercentChange} fontSizeOverride='lg' />
            </Box>
        )
    };

    return (
        data &&
        (
            <Box p={4} mr={isNarrow ? 5 : 10} ml={isNarrow ? 5 : 10} borderWidth={2} borderRadius={5} shadow='base'>
                <SimpleGrid minChildWidth='300px' spacing={'14px'}>
                    {
                        makeBox("Total DAU",
                            data.totalDAU,
                            data.totalOnePrevDAU,
                            data.totalWeekPrevDAU,
                            data.totalOnePrevDAUPercentageChange,
                            data.totalWeekPrevDAUPercentageChange,
                            0)
                    }
                    {
                        makeBox("Total iOS DAU",
                            data.iosTotalDAU,
                            data.iosTotalOnePrevDAU,
                            data.iosTotalWeekPrevDAU,
                            data.totalIOSOnePrevDAUPercentageChange,
                            data.totalIOSWeekPrevDAUPercentageChange,
                            isNarrow ? 5 : 0)
                    }
                    {
                        makeBox("Total Android DAU",
                            data.androidTotalDAU,
                            data.androidTotalOnePrevDAU,
                            data.androidTotalWeekPrevDAU,
                            data.totalAndroidOnePrevDAUPercentageChange,
                            data.totalAndroidWeekPrevDAUPercentageChange,
                            isNarrow ? 5 : 0)
                    }
                    {
                        makeBox("Total Web DAU",
                            data.webTotalDAU,
                            data.webTotalOnePrevDAU,
                            data.webTotalWeekPrevDAU,
                            data.totalWebOnePrevDAUPercentageChange,
                            data.totalWebWeekPrevDAUPercentageChange,
                            isNarrow ? 5 : 0)
                    }
                    {
                        makeBox("Top 10 Studios DAU",
                            data.top10StudiosDAU,
                            data.top10StudiosOnePrevDAU,
                            data.top10StudiosWeekPrevDAU,
                            data.top10StudiosOnePrevDAUPercentageChange,
                            data.top10StudiosWeekPrevDAUPercentageChange,
                            isNarrow ? 5 : 0)
                    }
                </SimpleGrid>
            </Box>
        )
    )
}

export default DAUBreakdown