import { Select, Box, Button, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, Spinner } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import NumberArrowPercentDisplay from './NumberArrowPercentDisplay'
import { IoSwapVertical } from 'react-icons/io5'
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa'

const RawBreakdown = ({ data, hideStudioFilter, overrideTopMargin, isNarrow,// }) => {
    sortKey, setSortKey, dauMinCutoff, setDauMinCutoff, dauMaxCutoff, setDauMaxCutoff,
    dauWeekAgoMinCutoff, setDauWeekAgoMinCutoff, dauWeekAgoMaxCutoff, setDauWeekAgoMaxCutoff, studioFilter, setStudioFilter,
    percentileCutoff, setPercentileCutoff, inverted, setInverted }) => {
    /*const defaultDAUCutoff = '100';
    const defaultStudioFilter = 'all';

    const [sortKey, setSortKey] = useState('dau');
    const [dauCutoff, setDauCutoff] = useState(defaultDAUCutoff);
    const [studioFilter, setStudioFilter] = useState(defaultStudioFilter);
    const [inverted, setInverted] = useState(false);*/

    //const [preSortedData, setPreSortedData] = useState(null);
    const firstGame = data[0];

    const filterData = (inputData, currentDauCutoff, currentDauMaxCutoff, currentDauWeekAgoMinCutoff, currentDauWeekAgoMaxCutoff, currentStudioFilter) => {
        const minDauCutoff = currentDauCutoff ? parseFloat(currentDauCutoff, 10) : 0;
        let minDauPercCutoff = 100;
        if (dauMinCutoff.includes('%')) {
            minDauPercCutoff = parseFloat(dauMinCutoff.replace('%', ''), 10);
        }

        const maxDauCutoff = currentDauMaxCutoff ? parseFloat(currentDauMaxCutoff, 10) : Number.POSITIVE_INFINITY;
        let maxDauPercCutoff = 0;
        if (dauMaxCutoff.includes('%')) {
            maxDauPercCutoff = parseFloat(dauMaxCutoff.replace('%', ''), 10);
        }

        const minDauWeekAgoCutoff = currentDauWeekAgoMinCutoff ? parseFloat(currentDauWeekAgoMinCutoff, 10) : 0;
        let minDauWeekAgoPercCutoff = 100;
        if (dauWeekAgoMinCutoff.includes('%')) {
            minDauWeekAgoPercCutoff = parseFloat(dauWeekAgoMinCutoff.replace('%', ''), 10);
        }

        const maxDauWeekAgoCutoff = currentDauWeekAgoMaxCutoff ? parseFloat(currentDauWeekAgoMaxCutoff, 10) : Number.POSITIVE_INFINITY;
        let maxDauWeekAgoPercCutoff = 0;
        if (dauWeekAgoMaxCutoff.includes('%')) {
            maxDauWeekAgoPercCutoff = parseFloat(dauWeekAgoMaxCutoff.replace('%', ''), 10);
        }

        /*console.log('minDauCutoff', minDauCutoff);
        console.log('minDauPercCutoff', minDauPercCutoff);
        console.log('maxDauCutoff', maxDauCutoff);
        console.log('maxDauPercCutoff', maxDauPercCutoff);
        console.log('minDauWeekAgoCutoff', minDauWeekAgoCutoff);
        console.log('minDauWeekAgoPercCutoff', minDauWeekAgoPercCutoff);
        console.log('maxDauWeekAgoCutoff', maxDauWeekAgoCutoff);
        console.log('maxDauWeekAgoPercCutoff', maxDauWeekAgoPercCutoff);

        console.log('data', inputData)

        inputData.forEach(game => {
            console.log(game.dau)
        })*/

        const getValidIndex = (array, perc, metric) => {
            let sortedArray = null;
            if (metric === 'DAU') {
                sortedArray = array.sort((a, b) => b.dau - a.dau);
            } else if (metric === 'weekPrevDAU') {
                sortedArray = array.sort((a, b) => b.weekPrevDau - a.weekPrevDau);
            }
            const index = Math.floor(sortedArray.length * (perc / 100)) - 1;
            return Math.max(0, Math.min(index, sortedArray.length - 1));
        };

        return inputData.filter(gameData =>
            (currentStudioFilter === 'all' || gameData.studio === currentStudioFilter)
            &&
            gameData.dau >= minDauCutoff
            &&
            gameData.dau >= inputData[getValidIndex(inputData, minDauPercCutoff, 'DAU')].dau
            &&
            gameData.dau <= maxDauCutoff
            &&
            gameData.dau <= inputData[getValidIndex(inputData, maxDauPercCutoff, 'DAU')].dau
            &&
            gameData.weekPrevDau >= minDauWeekAgoCutoff
            &&
            gameData.weekPrevDau >= inputData[getValidIndex(inputData, minDauWeekAgoPercCutoff, 'weekPrevDAU')].weekPrevDau
            &&
            gameData.weekPrevDau <= maxDauWeekAgoCutoff
            &&
            gameData.weekPrevDau <= inputData[getValidIndex(inputData, maxDauWeekAgoPercCutoff, 'weekPrevDAU')].weekPrevDau
        );
    };

    const getSortedData = (currentSortKey, currentInversionStatus) => {
        const sortedData = filterData(data, dauMinCutoff, dauMaxCutoff, dauWeekAgoMinCutoff, dauWeekAgoMaxCutoff, studioFilter)
            .sort((a, b) => {
                let diff;

                switch (currentSortKey) {
                    case 'dau':
                    case 'studioAppCount':
                    case 'onePrevDau':
                    case 'weekPrevDau':
                    case 'onePrevPercentChange':
                    case 'weekPrevPercentChange':
                        diff = b[currentSortKey] - a[currentSortKey];
                        break;
                    case 'onePrevChange':
                        diff = (b.dau - b.onePrevDau) - (a.dau - a.onePrevDau);
                        break;
                    case 'weekPrevChange':
                        diff = (b.dau - b.weekPrevDau) - (a.dau - a.weekPrevDau);
                        break;
                    case 'significantDayMovers':
                        diff = b['onePrevPercentChange'] * b.dau - a['onePrevPercentChange'] * a.dau;
                        break;
                    case 'significantWeekMovers':
                        diff = b['weekPrevPercentChange'] * b.dau - a['weekPrevPercentChange'] * a.dau;
                        break;
                    case 'studio':
                        diff = a.studio.toLowerCase().localeCompare(b.studio.toLowerCase());
                        break;
                    case 'name':
                        if (a.name && b.name) {
                            diff = a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                        } else {
                            diff = a.name ? -1 : 1;
                        }
                        break;
                    case 'dateAdded':
                        diff = new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime();
                        break;
                    default:
                        diff = b && b.dau ? b.dau - a.dau : 1;
                        break;
                }

                return currentInversionStatus ? -diff : diff;
            });

        //const percCutoff = parseInt(percentileCutoff, 10) / 100;
        //const slicedData = sortedData.slice(0, Math.floor(sortedData.length * percCutoff));

        //remove any duplicates

        let uniqueData;

        if (sortedData[0] && sortedData[0].studioAppCount) {
            uniqueData = sortedData.filter((game, index, self) =>
                index === self.findIndex((t) => (
                    t.studio === game.studio
                ))
            );
        } else {
            uniqueData = sortedData.filter((game, index, self) =>
                index === self.findIndex((t) => (
                    t.gameID === game.gameID
                ))
            );
        }

        //console.log('uniqueData', uniqueData)
        //console.log('sortedData', sortedData)

        //return sortedData;
        return uniqueData;
    };

    /*const getAllVariantsOfSortedData = () => {
        //get all variants of sorted data for each sort key
        const sortedData = {};
        const sortKeys = ['dau', 'studio', 'name', 'onePrevDau', 'weekPrevDau',
            'onePrevPercentChange', 'weekPrevPercentChange', 'onePrevChange',
            'weekPrevChange', 'significantDayMovers', 'significantWeekMovers'];

        sortKeys.forEach(key => {
            sortedData[key] = getSortedData(key, false);
        });

        return sortedData;
    };*/

    //const [sortedData, setSortedData] = useState(getSortedData(sortKey, inverted));
    const sortedData = getSortedData(sortKey, inverted);

    //useEffect(() => {
    //    setSortedData(getSortedData(sortKey, inverted))
    //}, [dauCutoff, studioFilter, inverted, sortKey])

    /*useEffect(() => {
        setPreSortedData(getAllVariantsOfSortedData());
    }, [data]);

    useEffect(() => {
        if (preSortedData) {
            setSortedData(preSortedData[sortKey]);
        }
    }, [preSortedData, sortKey]);*/

    if (!data || !sortedData) {
        return (
            <Flex direction="row" justifyContent="center" alignItems="flex-start" mt={10}>
                <Spinner size="xl" />
            </Flex>
        )
    }

    const handleSortChange = (e) => {
        setSortKey(e.target.value);
    };

    const handleDauMinCutoffChange = (e) => {
        setDauMinCutoff(e.target.value);
    }

    const handleDauMaxCutoffChange = (e) => {
        setDauMaxCutoff(e.target.value);
    }

    const handleDauWeekAgoMinCutoffChange = (e) => {
        setDauWeekAgoMinCutoff(e.target.value);
    }

    const handleDauWeekAgoMaxCutoffChange = (e) => {
        setDauWeekAgoMaxCutoff(e.target.value);
    }

    const handlePercentileCutoffChange = (e) => {
        setPercentileCutoff(e.target.value);
    }

    const handleStudioFilterChange = (e) => {
        setStudioFilter(e.target.value);
    }

    const getTotals = (presortedData) => {
        const totals = {
            dau: 0,
            onePrevDau: 0,
            weekPrevDau: 0,
            onePrevPercentChange: 0,
            weekPrevPercentChange: 0,
            appCount: 0,
            studioAppCount: 0,
            uniqueStudioCount: 0,
        };

        const uniqueStudios = new Set();

        //add up totals, recalculate percentages
        presortedData.forEach(game => {
            totals.dau += game.dau;
            totals.onePrevDau += game.onePrevDau;
            totals.weekPrevDau += game.weekPrevDau;
            totals.appCount++;
            totals.studioAppCount += game.studioAppCount;
            uniqueStudios.add(game.studio);
        });

        totals.onePrevPercentChange = totals.onePrevDau !== 0 ? (totals.dau - totals.onePrevDau) / totals.onePrevDau * 100 : 0;
        totals.weekPrevPercentChange = totals.weekPrevDau !== 0 ? (totals.dau - totals.weekPrevDau) / totals.weekPrevDau * 100 : 0;

        totals.uniqueStudioCount = uniqueStudios.size;

        return totals;
    };

    const totals = getTotals(sortedData);

    const pressColumnHeaderToSort = (category) => {
        if (sortKey != category) {
            setInverted(false)
            setSortKey(category)
        } else {
            setInverted(!inverted)
        }
    };

    const getArrowIconForCategory = (category) => {
        if (sortKey === category) {
            return inverted ? <FaLongArrowAltUp /> : <FaLongArrowAltDown />
        } else {
            return null
        }
    }

    return (
        <>
            <Flex
                direction={isNarrow ? 'column' : 'row'}
                alignItems="center"
                justifyContent="flex-end"
                mt={overrideTopMargin != null ? overrideTopMargin : 4} pl={4}
                mr={4} gap={2}>
                <Text mr={4} fontSize="md">Week Ago DAU Range:</Text>
                <Select w={130} onChange={handleDauWeekAgoMinCutoffChange} defaultValue={dauWeekAgoMinCutoff}>
                    <option value="0">0</option>
                    <option value="10">10</option>
                    <option value="100">100</option>
                    <option value="1000">1,000</option>
                    <option value="10000">10,000</option>
                    <option value="100000">100,000</option>
                    <option value="1000000">1,000,000</option>
                    <option value="10000000">10,000,000</option>
                    <option value="Infinity">Infinity</option>
                    <option value="1%">Top 1%</option>
                    <option value="5%">Top 5%</option>
                    <option value="10%">Top 10%</option>
                    <option value="25%">Top 25%</option>
                    <option value="50%">Top 50%</option>
                </Select>
                <Text fontSize="md">-</Text>
                <Select mr={4} w={130} onChange={handleDauWeekAgoMaxCutoffChange} defaultValue={dauWeekAgoMaxCutoff}>
                    <option value="0">0</option>
                    <option value="10">10</option>
                    <option value="100">100</option>
                    <option value="1000">1,000</option>
                    <option value="10000">10,000</option>
                    <option value="100000">100,000</option>
                    <option value="1000000">1,000,000</option>
                    <option value="10000000">10,000,000</option>
                    <option value="Infinity">Infinity</option>
                    <option value="1%">Top 1%</option>
                    <option value="5%">Top 5%</option>
                    <option value="10%">Top 10%</option>
                    <option value="25%">Top 25%</option>
                    <option value="50%">Top 50%</option>
                </Select>
                <Text mr={4} fontSize="md">DAU Range:</Text>
                <Select w={130} onChange={handleDauMinCutoffChange} defaultValue={dauMinCutoff}>
                    <option value="0">0</option>
                    <option value="10">10</option>
                    <option value="100">100</option>
                    <option value="1000">1,000</option>
                    <option value="10000">10,000</option>
                    <option value="100000">100,000</option>
                    <option value="1000000">1,000,000</option>
                    <option value="10000000">10,000,000</option>
                    <option value="Infinity">Infinity</option>
                    <option value="1%">Top 1%</option>
                    <option value="5%">Top 5%</option>
                    <option value="10%">Top 10%</option>
                    <option value="25%">Top 25%</option>
                    <option value="50%">Top 50%</option>
                </Select>
                <Text fontSize="md">-</Text>
                <Select mr={4} w={130} onChange={handleDauMaxCutoffChange} defaultValue={dauMaxCutoff}>
                    <option value="0">0</option>
                    <option value="10">10</option>
                    <option value="100">100</option>
                    <option value="1000">1,000</option>
                    <option value="10000">10,000</option>
                    <option value="100000">100,000</option>
                    <option value="1000000">1,000,000</option>
                    <option value="10000000">10,000,000</option>
                    <option value="Infinity">Infinity</option>
                    <option value="1%">Top 1%</option>
                    <option value="5%">Top 5%</option>
                    <option value="10%">Top 10%</option>
                    <option value="25%">Top 25%</option>
                    <option value="50%">Top 50%</option>
                </Select>
                {
                    /*
                    <Text mr={4} fontSize="md">Min. Percentile:</Text>
                    <Select mr={4} w={130} onChange={handlePercentileCutoffChange} defaultValue={percentileCutoff}>
                        <option value="1">1</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </Select>
                    */
                }
                {
                    !hideStudioFilter &&
                    <>
                        <Text mr={4} fontSize="md">Studio:</Text>
                        <Select mr={4} w={200} onChange={handleStudioFilterChange} defaultValue={studioFilter}>
                            <option value="all">All</option>
                            {
                                [...new Set(data.map(game => game.studio))]
                                    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                                    .map((studio, index) => (
                                        <option key={index} value={studio}>{studio}</option>
                                    ))
                            }
                        </Select>
                    </>
                }
                <Text mr={4} fontSize="md">Sort:</Text>
                <Select w={200} onChange={handleSortChange} value={sortKey}>
                    <option value="dau">DAU</option>
                    <option value="studio">Studio</option>
                    <option value="name">Game</option>
                    <option value="dateAdded">Date Added</option>
                    <option value="studioAppCount">App Count</option>
                    <option value="onePrevDau">Day DAU</option>
                    <option value="weekPrevDau">Week DAU</option>
                    <option value="onePrevPercentChange">Day % Change</option>
                    <option value="weekPrevPercentChange">Week % Change</option>
                    <option value="onePrevChange">Day DAU Change</option>
                    <option value="weekPrevChange">Week DAU Change</option>
                    <option value="significantDayMovers">Significant Day Movers</option>
                    <option value="significantWeekMovers">Significant Week Movers</option>
                </Select>
                <Button ml={4} minWidth={144} onClick={() => {
                    setInverted(!inverted);
                }}>
                    <Flex direction="row" align="center" justifyContent="center">
                        <Text textAlign="center">
                            {inverted ? 'Ascending' : 'Descending'}
                        </Text>
                        &nbsp;
                        <IoSwapVertical />
                    </Flex>
                </Button>
            </Flex>
            <Box pl={4} borderWidth={2} borderRadius={5} shadow='base' mt={4} overflowY='auto' maxHeight={600}>
                <TableContainer overflowX="unset" overflowY="unset" ml={-5}>
                    <Table variant='simple'>
                        <Thead position="sticky" top={0} bgColor='#49BEAA' zIndex={100}>
                            <Tr>
                                {
                                    firstGame.gameID &&
                                    <Th sx={{ userSelect: 'none' }}>Game ID</Th>
                                }
                                <Th onClick={() => { pressColumnHeaderToSort('studio') }} >
                                    <Flex direction="row" justifyContent="flex-start" align="center">
                                        <Text sx={{ userSelect: 'none' }}>Studio</Text>
                                        {getArrowIconForCategory('studio')}
                                    </Flex>
                                </Th>
                                {
                                    firstGame.name &&
                                    <Th onClick={() => { pressColumnHeaderToSort('name') }} >
                                        <Flex direction="row" justifyContent="flex-start" align="center">
                                            <Text sx={{ userSelect: 'none' }}>Game</Text>
                                            {getArrowIconForCategory('name')}
                                        </Flex>
                                    </Th>
                                }
                                {
                                    firstGame.platform &&
                                    <Th sx={{ userSelect: 'none' }}>Platform</Th>
                                }
                                {
                                    firstGame.studioAppCount &&
                                    <Th onClick={() => { pressColumnHeaderToSort('studioAppCount') }}>
                                        <Flex direction="row" justifyContent="flex-start" align="center">
                                            <Text sx={{ userSelect: 'none' }}>App Count</Text>
                                            {getArrowIconForCategory('studioAppCount')}
                                        </Flex>
                                    </Th>
                                }
                                <Th onClick={() => { pressColumnHeaderToSort('dau') }} >
                                    <Flex direction="row" justifyContent="flex-start" align="center">
                                        <Text sx={{ userSelect: 'none' }}>DAU</Text>
                                        {getArrowIconForCategory('dau')}
                                    </Flex>
                                </Th>
                                <Th onClick={() => { pressColumnHeaderToSort('onePrevDau') }}>
                                    <Flex direction="row" justifyContent="flex-start" align="center">
                                        <Text sx={{ userSelect: 'none' }}>Day Ago</Text>
                                        {getArrowIconForCategory('onePrevDau')}
                                    </Flex>
                                </Th>
                                <Th onClick={() => { pressColumnHeaderToSort('weekPrevDau') }}>
                                    <Flex direction="row" justifyContent="flex-start" align="center">
                                        <Text sx={{ userSelect: 'none' }}>Week Ago</Text>
                                        {getArrowIconForCategory('weekPrevDau')}
                                    </Flex>
                                </Th>
                                {
                                    firstGame.link &&
                                    <Th sx={{ userSelect: 'none' }}>Dashboard</Th>
                                }
                                {
                                    firstGame.dateAdded &&
                                    <Th onClick={() => { pressColumnHeaderToSort('dateAdded') }} >
                                        <Flex direction="row" justifyContent="flex-start" align="center">
                                            <Text sx={{ userSelect: 'none' }}>Created</Text>
                                            {getArrowIconForCategory('dateAdded')}
                                        </Flex>
                                    </Th>
                                }
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                sortedData.map((game, index) => {
                                    return (
                                        <Tr key={index}>
                                            {
                                                game.gameID &&
                                                <Td>{game.gameID}</Td>
                                            }
                                            <Td>
                                                <Text isTruncated maxWidth={200}>
                                                    {game.studio}
                                                </Text>
                                            </Td>
                                            {
                                                game.name &&
                                                <Td>
                                                    <Text isTruncated maxWidth={200}>
                                                        {game.name}
                                                    </Text>
                                                </Td>
                                            }
                                            {
                                                game.platform &&
                                                <Td>
                                                    <Text isTruncated maxWidth={200}>
                                                        {game.platform}
                                                    </Text>
                                                </Td>
                                            }
                                            {
                                                game.studioAppCount &&
                                                <Td>{game.studioAppCount.toLocaleString()}</Td>
                                            }
                                            <Td>{game.dau.toLocaleString()}</Td>
                                            <Td>
                                                <Flex direction="row" justifyContent="flex-start">
                                                    <NumberArrowPercentDisplay totalNumber={game.onePrevDau} percentChange={game.onePrevPercentChange} />
                                                </Flex>
                                            </Td>
                                            <Td>
                                                <Flex direction="row" justifyContent="flex-start">
                                                    <NumberArrowPercentDisplay totalNumber={game.weekPrevDau} percentChange={game.weekPrevPercentChange} />
                                                </Flex>
                                            </Td>
                                            {
                                                game.link &&
                                                <Td>
                                                    <Button height={8} onClick={
                                                        () => {
                                                            window.open(game.link)
                                                        }
                                                    }>
                                                        View
                                                    </Button>
                                                </Td>
                                            }
                                            {
                                                game.dateAdded &&
                                                <Td>{new Date(game.dateAdded).toLocaleDateString()}</Td>
                                            }
                                        </Tr>
                                    )
                                })

                            }
                        </Tbody>
                        <Thead position="sticky" bottom={-1} bgColor='#373F51' zIndex={100} height={14}>
                            <Tr>
                                {
                                    firstGame.gameID &&
                                    <Th fontSize={14} color='#F6F8FF'>Totals</Th>
                                }
                                <Th fontSize={14} color='#F6F8FF' pr={-2}>
                                    {
                                        totals &&
                                        totals.uniqueStudioCount.toLocaleString() + ' Studios'
                                    }
                                </Th>
                                {
                                    firstGame.name &&
                                    <Th fontSize={14} color='#F6F8FF' pr={-2}>
                                        {
                                            totals &&
                                            totals.appCount.toLocaleString() + ' Games'
                                        }
                                    </Th>
                                }
                                {
                                    firstGame.platform &&
                                    <Th></Th>
                                }
                                {
                                    firstGame.studioAppCount &&
                                    <Th fontSize={14} color='#F6F8FF' pr={-2}>
                                        {
                                            totals &&
                                            totals.studioAppCount.toLocaleString() + ' Games'
                                        }
                                    </Th>
                                }
                                <Th fontSize={14} color='#F6F8FF' pr={-2}>{
                                    totals &&
                                    totals.dau.toLocaleString()
                                }</Th>
                                <Th fontSize={14} color='#F6F8FF' pr={-2}>
                                    <Flex direction="row" justifyContent="flex-start">
                                        <NumberArrowPercentDisplay totalNumber={totals.onePrevDau} percentChange={totals.onePrevPercentChange} />
                                    </Flex>
                                </Th>
                                <Th fontSize={14} color='#F6F8FF' pr={-2}>
                                    <Flex direction="row" justifyContent="flex-start">
                                        <NumberArrowPercentDisplay totalNumber={totals.weekPrevDau} percentChange={totals.weekPrevPercentChange} />
                                    </Flex>
                                </Th>
                                {
                                    firstGame.link &&
                                    <Th></Th>
                                }
                                {
                                    firstGame.dateAdded &&
                                    <Th></Th>
                                }
                            </Tr>
                        </Thead>
                    </Table>
                </TableContainer>
            </Box>
            <Text fontSize="sm" textAlign="left" >{`* Games with less than ${parseInt(dauMinCutoff).toLocaleString()} DAU are filtered.`}</Text>
            {/*
            <BreakdownTotalsSummary data={sortedData} />
            */}
        </>
    )
}

export default RawBreakdown