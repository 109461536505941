import { Box } from '@chakra-ui/react'
import React from 'react'
import CSVReader from './CSVReader'

const gameIDColumn = 'gameID';
const platformColumn = 'Platform';
const dauColumn = 'DAU';
const onePrevDauColumn = 'onePrevDAU';
const weekPrevDauColumn = 'weekPrevDAU';
const appTitleColumn = 'appTitle';
const companyColumn = 'company';
const bundleIDColumn = 'bundleID';
const dateAddedColumn = 'createdOn';
const linkColumn = 'dashboardLink';

const getColumnIndex = (columnName, columnArray) => {
    return columnArray.indexOf(columnName)
}

const CSVDataMapper = ({ onDataMapped, setData, isNarrow }) => {
    return (
        <Box p={4} mr={isNarrow ? 5 : 10} ml={isNarrow ? 5 : 10} mt={6} borderWidth={2} borderRadius={5} shadow='base'>
            <CSVReader isNarrow={isNarrow} onDataReceived={async (csvData) => {
                setData(null)
                if (csvData) {
                    if (csvData.data) {
                        let promises = {}

                        //remove any dupes
                        csvData.data = csvData.data.filter((v, i, a) => a.findIndex(t => (t[0] === v[0] && t[1] === v[1])) === i);

                        //all data
                        const allDataStep =
                            new Promise((resolve, reject) => {
                                try {
                                    const rawData = csvData.data
                                    const columnArray = rawData[0]
                                    const allData = rawData.slice(1).map(element => {
                                        //const currentDau = parseInt(element[2]);
                                        const currentDau = parseInt(element[getColumnIndex(dauColumn, columnArray)]);
                                        //const onePrevDau = parseInt(element[3]);
                                        const onePrevDau = parseInt(element[getColumnIndex(onePrevDauColumn, columnArray)]);
                                        //const weekPrevDau = parseInt(element[4]);
                                        const weekPrevDau = parseInt(element[getColumnIndex(weekPrevDauColumn, columnArray)]);

                                        const onePrevPercentChange = onePrevDau !== 0 ? (currentDau - onePrevDau) / onePrevDau * 100 : 0;
                                        const weekPrevPercentChange = weekPrevDau !== 0 ? (currentDau - weekPrevDau) / weekPrevDau * 100 : 0;

                                        return {
                                            //name: element[5],
                                            name: element[getColumnIndex(appTitleColumn, columnArray)],
                                            //studio: element[6],
                                            studio: element[getColumnIndex(companyColumn, columnArray)],
                                            //gameID: element[0],
                                            gameID: element[getColumnIndex(gameIDColumn, columnArray)],
                                            //platform: element[1],
                                            platform: element[getColumnIndex(platformColumn, columnArray)],
                                            dau: currentDau,
                                            onePrevDau: onePrevDau,
                                            weekPrevDau: weekPrevDau,
                                            onePrevPercentChange: onePrevPercentChange,
                                            weekPrevPercentChange: weekPrevPercentChange,
                                            //link: element[8],
                                            link: element[getColumnIndex(linkColumn, columnArray)],
                                            //dateAdded: new Date(), //element[9],
                                            dateAdded: Date.parse(element[getColumnIndex(dateAddedColumn, columnArray)]) || new Date(+0),
                                        }
                                    });

                                    resolve(allData)
                                } catch (error) {
                                    reject(error)
                                }
                            });

                        const androidDataStep = new Promise((resolve, reject) => {
                            try {
                                const rawData = csvData.data;
                                const columnArray = rawData[0];
                                const rawAndroidData = rawData.filter((row) => row[1] === 'Android');
                                const androidData = rawAndroidData.map(element => {
                                    //const currentDau = parseInt(element[2]);
                                    const currentDau = parseInt(element[getColumnIndex(dauColumn, columnArray)]);
                                    //const onePrevDau = parseInt(element[3]);
                                    const onePrevDau = parseInt(element[getColumnIndex(onePrevDauColumn, columnArray)]);
                                    //const weekPrevDau = parseInt(element[4]);
                                    const weekPrevDau = parseInt(element[getColumnIndex(weekPrevDauColumn, columnArray)]);

                                    const onePrevPercentChange = onePrevDau !== 0 ? (currentDau - onePrevDau) / onePrevDau * 100 : 0;
                                    const weekPrevPercentChange = weekPrevDau !== 0 ? (currentDau - weekPrevDau) / weekPrevDau * 100 : 0;

                                    return {
                                        //name: element[5],
                                        name: element[getColumnIndex(appTitleColumn, columnArray)],
                                        //studio: element[6],
                                        studio: element[getColumnIndex(companyColumn, columnArray)],
                                        //gameID: element[0],
                                        gameID: element[getColumnIndex(gameIDColumn, columnArray)],
                                        dau: currentDau,
                                        onePrevDau: onePrevDau,
                                        weekPrevDau: weekPrevDau,
                                        onePrevPercentChange: onePrevPercentChange,
                                        weekPrevPercentChange: weekPrevPercentChange,
                                        //link: element[8],
                                        link: element[getColumnIndex(linkColumn, columnArray)],
                                        //dateAdded: new Date(), //element[9],
                                        dateAdded: Date.parse(element[getColumnIndex(dateAddedColumn, columnArray)]) || new Date(+0),
                                    }
                                });

                                resolve(androidData);
                            } catch (error) {
                                reject(error);
                            }
                        });

                        const iosDataStep =
                            new Promise((resolve, reject) => {
                                try {
                                    const rawData = csvData.data
                                    const columnArray = rawData[0]
                                    const rawIOSData = rawData.filter((row) => row[1] === 'iOS')
                                    const iosData = rawIOSData.map(element => {
                                        //const currentDau = parseInt(element[2]);
                                        const currentDau = parseInt(element[getColumnIndex(dauColumn, columnArray)]);
                                        //const onePrevDau = parseInt(element[3]);
                                        const onePrevDau = parseInt(element[getColumnIndex(onePrevDauColumn, columnArray)]);
                                        //const weekPrevDau = parseInt(element[4]);
                                        const weekPrevDau = parseInt(element[getColumnIndex(weekPrevDauColumn, columnArray)]);

                                        const onePrevPercentChange = onePrevDau !== 0 ? (currentDau - onePrevDau) / onePrevDau * 100 : 0;
                                        const weekPrevPercentChange = weekPrevDau !== 0 ? (currentDau - weekPrevDau) / weekPrevDau * 100 : 0;
                                        return {
                                            //name: element[5],
                                            name: element[getColumnIndex(appTitleColumn, columnArray)],
                                            //studio: element[6],
                                            studio: element[getColumnIndex(companyColumn, columnArray)],
                                            //gameID: element[0],
                                            gameID: element[getColumnIndex(gameIDColumn, columnArray)],
                                            dau: currentDau,
                                            onePrevDau: onePrevDau,
                                            weekPrevDau: weekPrevDau,
                                            onePrevPercentChange: onePrevPercentChange,
                                            weekPrevPercentChange: weekPrevPercentChange,
                                            //link: element[8],
                                            link: element[getColumnIndex(linkColumn, columnArray)],
                                            //dateAdded: new Date(), //element[9],
                                            dateAdded: Date.parse(element[getColumnIndex(dateAddedColumn, columnArray)]) || new Date(+0),
                                        }
                                    });

                                    resolve(iosData)
                                } catch (error) {
                                    reject(error)
                                }
                            });

                        const webDataStep = new Promise((resolve, reject) => {
                            try {
                                const rawData = csvData.data;
                                const columnArray = rawData[0];
                                const rawWebData = rawData.filter((row) => row[1] === 'Web');
                                const webData = rawWebData.map(element => {
                                    //const currentDau = parseInt(element[2]);
                                    const currentDau = parseInt(element[getColumnIndex(dauColumn, columnArray)]);
                                    //const onePrevDau = parseInt(element[3]);
                                    const onePrevDau = parseInt(element[getColumnIndex(onePrevDauColumn, columnArray)]);
                                    //const weekPrevDau = parseInt(element[4]);
                                    const weekPrevDau = parseInt(element[getColumnIndex(weekPrevDauColumn, columnArray)]);

                                    const onePrevPercentChange = onePrevDau !== 0 ? (currentDau - onePrevDau) / onePrevDau * 100 : 0;
                                    const weekPrevPercentChange = weekPrevDau !== 0 ? (currentDau - weekPrevDau) / weekPrevDau * 100 : 0;

                                    return {
                                        //name: element[5],
                                        name: element[getColumnIndex(appTitleColumn, columnArray)],
                                        //studio: element[6],
                                        studio: element[getColumnIndex(companyColumn, columnArray)],
                                        //gameID: element[0],
                                        gameID: element[getColumnIndex(gameIDColumn, columnArray)],
                                        dau: currentDau,
                                        onePrevDau: onePrevDau,
                                        weekPrevDau: weekPrevDau,
                                        onePrevPercentChange: onePrevPercentChange,
                                        weekPrevPercentChange: weekPrevPercentChange,
                                        //link: element[8],
                                        link: element[getColumnIndex(linkColumn, columnArray)],
                                        //dateAdded: new Date(), //element[9],
                                        dateAdded: Date.parse(element[getColumnIndex(dateAddedColumn, columnArray)]) || new Date(+0),
                                    }
                                });

                                resolve(webData);
                            } catch (error) {
                                reject(error);
                            }
                        });

                        promises = { ...promises, allDataStep, androidDataStep, iosDataStep, webDataStep }

                        const totalAndroidDAUStep = (androidData) =>
                            new Promise((resolve, reject) => {
                                try {
                                    const totalAndroidDAU = androidData.reduce((total, element) => total + element.dau, 0);
                                    const totalAndroidOnePrevDAU = androidData.reduce((total, element) => total + element.onePrevDau, 0);
                                    const totalAndroidWeekPrevDAU = androidData.reduce((total, element) => total + element.weekPrevDau, 0);

                                    const totalAndroidOnePrevDAUPercentageChange = totalAndroidOnePrevDAU !== 0 ? (totalAndroidDAU - totalAndroidOnePrevDAU) / totalAndroidOnePrevDAU * 100 : 0;
                                    const totalAndroidWeekPrevDAUPercentageChange = totalAndroidWeekPrevDAU !== 0 ? (totalAndroidDAU - totalAndroidWeekPrevDAU) / totalAndroidWeekPrevDAU * 100 : 0;

                                    resolve({
                                        totalAndroidDAU: totalAndroidDAU,
                                        totalAndroidOnePrevDAU: totalAndroidOnePrevDAU,
                                        totalAndroidWeekPrevDAU: totalAndroidWeekPrevDAU,
                                        totalAndroidOnePrevDAUPercentageChange: totalAndroidOnePrevDAUPercentageChange,
                                        totalAndroidWeekPrevDAUPercentageChange: totalAndroidWeekPrevDAUPercentageChange,
                                    })
                                } catch (error) {
                                    reject(error)
                                }
                            });

                        const totalIOSDAUStep = (iosData) =>
                            new Promise((resolve, reject) => {
                                try {
                                    const totalIOSDAU = iosData.reduce((total, element) => total + element.dau, 0);
                                    const totalIOSOnePrevDAU = iosData.reduce((total, element) => total + element.onePrevDau, 0);
                                    const totalIOSWeekPrevDAU = iosData.reduce((total, element) => total + element.weekPrevDau, 0);

                                    const totalIOSOnePrevDAUPercentageChange = totalIOSOnePrevDAU !== 0 ? (totalIOSDAU - totalIOSOnePrevDAU) / totalIOSOnePrevDAU * 100 : 0;
                                    const totalIOSWeekPrevDAUPercentageChange = totalIOSWeekPrevDAU !== 0 ? (totalIOSDAU - totalIOSWeekPrevDAU) / totalIOSWeekPrevDAU * 100 : 0;

                                    resolve({
                                        totalIOSDAU: totalIOSDAU,
                                        totalIOSOnePrevDAU: totalIOSOnePrevDAU,
                                        totalIOSWeekPrevDAU: totalIOSWeekPrevDAU,
                                        totalIOSOnePrevDAUPercentageChange: totalIOSOnePrevDAUPercentageChange,
                                        totalIOSWeekPrevDAUPercentageChange: totalIOSWeekPrevDAUPercentageChange,
                                    })
                                } catch (error) {
                                    reject(error)
                                }
                            });

                            const totalWebDAUStep = (webData) =>
                                new Promise((resolve, reject) => {
                                    try {
                                        const totalWebDAU = webData.reduce((total, element) => total + element.dau, 0);
                                        const totalWebOnePrevDAU = webData.reduce((total, element) => total + element.onePrevDau, 0);
                                        const totalWebWeekPrevDAU = webData.reduce((total, element) => total + element.weekPrevDau, 0);
    
                                        const totalWebOnePrevDAUPercentageChange = totalWebOnePrevDAU !== 0 ? (totalWebDAU - totalWebOnePrevDAU) / totalWebOnePrevDAU * 100 : 0;
                                        const totalWebWeekPrevDAUPercentageChange = totalWebWeekPrevDAU !== 0 ? (totalWebDAU - totalWebWeekPrevDAU) / totalWebWeekPrevDAU * 100 : 0;
    
                                        resolve({
                                            totalWebDAU: totalWebDAU,
                                            totalWebOnePrevDAU: totalWebOnePrevDAU,
                                            totalWebWeekPrevDAU: totalWebWeekPrevDAU,
                                            totalWebOnePrevDAUPercentageChange: totalWebOnePrevDAUPercentageChange,
                                            totalWebWeekPrevDAUPercentageChange: totalWebWeekPrevDAUPercentageChange,
                                        })
                                    } catch (error) {
                                        reject(error)
                                    }
                                });

                        promises = { ...promises, totalAndroidDAUStep, totalIOSDAUStep, totalWebDAUStep }

                        const dauByStudio =
                            new Promise((resolve, reject) => {
                                try {
                                    const rawData = csvData.data
                                    const columnArray = rawData[0]

                                    //studio name is index 6, dau is index 2. we want to sum up all the dau for each studio
                                    //create an object with studio name as key and object with dau, onePrevDau, weekPrevDau as value
                                    const dauByStudio = rawData.slice(1).reduce((acc, curr) => {
                                        //if (!acc[curr[6]]) {
                                        const studioName = curr[getColumnIndex(companyColumn, columnArray)];
                                        if (!acc[studioName]) {
                                            acc[studioName] = {
                                                dau: 0,
                                                onePrevDau: 0,
                                                weekPrevDau: 0
                                            }
                                        }
                                        //acc[curr[6]].dau += parseInt(curr[2])
                                        acc[studioName].dau += parseInt(curr[getColumnIndex(dauColumn, columnArray)]);
                                        //acc[curr[6]].onePrevDau += parseInt(curr[3])
                                        acc[studioName].onePrevDau += parseInt(curr[getColumnIndex(onePrevDauColumn, columnArray)]);
                                        //acc[curr[6]].weekPrevDau += parseInt(curr[4])
                                        acc[studioName].weekPrevDau += parseInt(curr[getColumnIndex(weekPrevDauColumn, columnArray)]);
                                        return acc
                                    }, {});

                                    //get top 100 studios by dau. each element is an object with studio name for key, and object with dau, onePrevDau, weekPrevDau as value
                                    const studiosData = Object.entries(dauByStudio).sort((a, b) => b[1].dau - a[1].dau).map(element => {
                                        const currentDau = parseInt(element[1].dau);
                                        const onePrevDau = parseInt(element[1].onePrevDau);
                                        const weekPrevDau = parseInt(element[1].weekPrevDau);

                                        const onePrevPercentChange = onePrevDau !== 0 ? (currentDau - onePrevDau) / onePrevDau * 100 : 0;
                                        const weekPrevPercentChange = weekPrevDau !== 0 ? (currentDau - weekPrevDau) / weekPrevDau * 100 : 0;

                                        const studioAppCount = rawData.slice(1).filter(row => row[getColumnIndex(companyColumn, columnArray)] === element[0]).length;
                                        return {
                                            studio: element[0],
                                            studioAppCount: studioAppCount,
                                            dau: currentDau,
                                            onePrevDau: onePrevDau,
                                            weekPrevDau: weekPrevDau,
                                            onePrevPercentChange: onePrevPercentChange,
                                            weekPrevPercentChange: weekPrevPercentChange,
                                        }
                                    })

                                    const top10StudiosDAU = studiosData.slice(0, 10).reduce((total, element) => total + element.dau, 0);
                                    const top10StudiosOnePrevDAU = studiosData.slice(0, 10).reduce((total, element) => total + element.onePrevDau, 0);
                                    const top10StudiosWeekPrevDAU = studiosData.slice(0, 10).reduce((total, element) => total + element.weekPrevDau, 0);

                                    const top10StudiosOnePrevDAUPercentageChange = (top10StudiosDAU - top10StudiosOnePrevDAU) / top10StudiosOnePrevDAU * 100;
                                    const top10StudiosWeekPrevDAUPercentageChange = (top10StudiosDAU - top10StudiosWeekPrevDAU) / top10StudiosWeekPrevDAU * 100;

                                    resolve({
                                        studiosData: studiosData,
                                        top10StudiosDAU: top10StudiosDAU,
                                        top10StudiosOnePrevDAU: top10StudiosOnePrevDAU,
                                        top10StudiosWeekPrevDAU: top10StudiosWeekPrevDAU,
                                        top10StudiosOnePrevDAUPercentageChange: top10StudiosOnePrevDAUPercentageChange,
                                        top10StudiosWeekPrevDAUPercentageChange: top10StudiosWeekPrevDAUPercentageChange,
                                    })
                                } catch (error) {
                                    reject(error)
                                }
                            });

                        promises = { ...promises, dauByStudio }

                        onDataMapped(promises)
                    } else {
                        onDataMapped(null)
                    }
                } else {
                    onDataMapped(null)
                }
            }} />
        </Box>
    )
}

export default CSVDataMapper