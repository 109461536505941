import { Box, Divider, Text } from '@chakra-ui/react'
import React from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts'

const Top10Android = ({ data }) => {
    if (!data || !data.androidData) {
        return null
    }
    
    return (
        <Box p={4} borderWidth={2} borderRadius={5} shadow='base'>
            <Text fontSize="lg" textAlign="center" fontWeight={500}>Top 10 Android</Text>
            <Divider mt={2} mb={2} />
            <ResponsiveContainer width="100%" height={280}>
                <BarChart width={400} height={280} data={data.androidData.slice(0, 10)} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
                    <XAxis dataKey="name" xAxisId={0} tickMargin={10} fontSize={14} />
                    <XAxis dataKey="studio" xAxisId={1} axisLine={false} tickMargin={0} tickLine={false} fontSize={14} />
                    <XAxis dataKey="gameID" xAxisId={2} axisLine={false} tickMargin={-8} tickLine={false} fontSize={14} />
                    <YAxis yAxisId={0} />
                    <Tooltip />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Bar fill="#456990" dataKey="weekPrevDau" yAxisId={0} name="Week Ago" />
                    <Bar fill="#49BEAA" dataKey="onePrevDau" yAxisId={0} name="Day Ago" />
                    <Bar fill="#EF767A" dataKey="dau" yAxisId={0} name="DAU" />
                </BarChart>
            </ResponsiveContainer>
        </Box>
    )
}

export default Top10Android