import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa'

const NumberArrowPercentDisplay = ({ label, totalNumber, percentChange, fontSizeOverride }) => {
    return (
        <Flex direction="row" align="center" justifyContent="center">
            <Text fontSize={fontSizeOverride ? fontSizeOverride : ''} textAlign="center">
                {label ? `${label}: ${totalNumber.toLocaleString()}` : totalNumber.toLocaleString()}
            </Text>
            &nbsp;
            <Text>
                {'('}
            </Text>
            {
                (percentChange >= 0 ? <FaLongArrowAltUp color='#49BEAA' /> : <FaLongArrowAltDown color='#EF767A' />)
            }
            <Text fontSize={fontSizeOverride ? fontSizeOverride : ''} textAlign="center">
                {Math.abs(percentChange).toFixed(2)}%
            </Text>
            <Text>
                {')'}
            </Text>
        </Flex>
    )
}

export default NumberArrowPercentDisplay